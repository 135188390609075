/* package default styles */
.component-slider {
    
    position: relative;
    width: 100%;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
  .component-slider .slider-container {
    margin: 0;
    overflow: hidden;
    display: inline-block;
  }
  .component-slider .slider-content {
    transition:margin-left 0.50s ease-in;
    
  }
  .component-slider .caret {
    position: absolute;
    top: 85%;
    transform: translateY(-98%);
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    color: #797979;
  }
  
  .component-slider .caret:hover,
  .component-slider .caret:focus,
  .component-slider .caret:active {
    border: none;
    outline: none;
  }
  .component-slider .caret-right {
    right: 0;
    margin-left: 10px;
    
  }
  .component-slider .caret-left {
    z-index: 111111111 !important;
    left: 0;
    margin-right: 10px;
   
    
  }
  
  /*custom styles*/
  .menu-bar {
    border-left:0.5px solid grey !important;
    border-right:0.5px solid grey;
    overflow: hidden;
    /* border-left-width:thin; */
    /* border-right-width:thin; */
    
    
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background:#FFFFFF;
    border: 1px solid #dfe1e5;
    border-radius: 50%;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.25);
    z-index: 1000000;
    color: #262626;
    font-weight: 700;
    outline: none;
    cursor: pointer;
  }

  .arrow-prev::after,
  .arrow-next::after {
    content: "";
    display: inline-block;
    height: 17px;
    width: 17px;
    border-bottom: 2px solid #262626;
    border-right: 2px solid #262626;
    border-color: #262626;
    outline: none;
    cursor: pointer;
  }
  
  .arrow-prev::after {
    transform: rotate(135deg);
    margin-left: 10px;
    z-index: 111111111 !important;
  }
  .arrow-next::after {
    margin-right: 10px;
    transform: rotate(315deg);
  }
  