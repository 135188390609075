.uppy-Dashboard-AddFiles-title{
    display: none !important;
}
.uppy-StatusBar-actionBtn--done{
    display: none !important;
}
.uppy-DashboardTab-name{
    font-size:14px !important;
}

.uppy-size--md .uppy-DashboardTab svg {
    width: 50px;
    height: 50px;
  }

.uppy-size--md .uppy-DashboardTab-btn{
    margin:30px
}

.uppy-Dashboard-inner{
    height: 400px !important;
}
.uppy-Dashboard{
    display: flex;
    justify-content: center;
    padding-bottom: 7px;
}
@media screen and (max-width: 750px) {
    .uppy-Dashboard-inner{
        height: 65vh !important;
    }
  }
@media screen and (max-width:632px) {
    .uppy-DashboardTab-name{
        font-size:14px !important;
    }
    .uppy-Dashboard-AddFiles-list .uppy-DashboardTab svg {
        width: 38px;
        height: 38px;
      }
}